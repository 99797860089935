import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Image = ({name}) => {
    switch(name) {
        case 'gibbon-desktop':
            return (<StaticImage src={'../images/gibbon-desktop.png'}  alt='' width={640} />);
        
        case 'healthcare':
            return (<StaticImage src={'../images/healthcare-solutions.png'}  alt='' width={640} />);
        
        
        case 'nesisai-productity':
            return (<StaticImage src={'../images/nesisai-productity-333x500.png'}  alt=''/>);
        
        default:
            return (<StaticImage src={'../images/solutions.webp'}  alt='' width={640} />);
    }
}

export default Image;