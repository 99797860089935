import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Iconify from '../../../../src/components/iconify';

export default function BodyItem({ content }) {
  return (
    <List sx={{ width: '100%' }}>
      {content?.benefits && content?.benefits.map((benefit) => <ListItem>
        <ListItemAvatar>
          <Avatar>
            <Iconify icon={benefit?.icon?.name} color={benefit?.icon?.color} width={benefit?.icon?.size} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText 
          primary={benefit.name} 
          primaryTypographyProps={{ fontSize: '22px', fontWeight: "bold" }} 
          secondary={benefit.description} 
          secondaryTypographyProps={{ fontSize: '20px', color: 'black' }} />
      </ListItem>)}
    </List>
  );
}
