import styled from 'styled-components';
import { device } from '../../utils/breakpoints';

const PageContentWrapper = styled.div`
  background-color: ${(props) => props.theme.backgroundSecondary};
  padding-bottom: 70px;
  position: relative;

  @media ${device.laptop} {
    padding-top: 50px;
    padding-bottom: 60px;
  }
`;

export default PageContentWrapper;
