// extracted by mini-css-extract-plugin
export var backgroundimagescover = "Home-module--backgroundimagescover--yf7fx";
export var bgfreebutton = "Home-module--bgfreebutton--8c8wO";
export var fontheader = "Home-module--fontheader--m9CDZ";
export var freetrialButton = "Home-module--freetrialButton--eog2L";
export var headerbgimg = "Home-module--headerbgimg--E1C5Y";
export var headercontent = "Home-module--headercontent--wu-Qo";
export var headerimg = "Home-module--headerimg--qaDIw";
export var homeimage = "Home-module--homeimage--AqbQm";
export var playwatch = "Home-module--playwatch--+sGET";
export var py50 = "Home-module--py50--wrg6j";