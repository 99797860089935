import React from 'react';
import Seo from '../../../components/Seo';
import MarginContainer from '../../../components/MarginContainer';
import PageContentWrapper from '../../../components/pageElements/PageContentWrapper';
import Header from './header';
import Body from './body';

function Page({content}) {

  return (
    <>
      <Seo title="Solutions" />
      <PageContentWrapper>
        <MarginContainer>
          <Header content={content}/>
          <Body content={content}/>
        </MarginContainer>
      </PageContentWrapper>
    </>
  );
}

export default Page;
