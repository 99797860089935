import styled from 'styled-components';
import { device } from '../utils/breakpoints';

const MarginContainer = styled.div`
  max-width: ${(props) => (props.$wide ? '1520px' : '1250px')};
  margin: auto;
  position: relative;

  @media ${device.tablet} {
    padding: 0 16px;
  }
`;

export default MarginContainer;
