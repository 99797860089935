import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import * as classes from '../../../custom-styles/Home.module.css'
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { device } from '../../../utils/breakpoints';
import Iconify from '../../../../src/components/iconify';

const HeaderContent = styled.div`
    padding: 40px 20px !important;
    margin:0;
    padding: 12px 0;
        & h1{
    font-size: 40px;
    font-weight: 550;
    margin:0;
    padding: 18px 0;
    @media ${device.tablet} {
        font-size: 40px;
        font-weight: 550;
        }
}
        & p{    
    font-size: 26px;
    margin:0;
    padding: 12px 0;
    @media ${device.tablet} {
        font-size: 18px;
        font-weight: 400;
    }
}
`;


export default function Header({ content }) {

  return (
    <Container className={`${classes.py50}`}>
      <Row>
        <Col>
          <HeaderContent>
            <Stack spacing={{ xs: 1, sm: 1 }}
              direction="row"
              useFlexGap
              sx={{ flexWrap: 'wrap', mb: 2 }}>
              <Iconify icon={content?.icon?.name} color={content?.icon?.color} width={content?.icon?.size} />
              <Typography variant="h4" gutterBottom><strong>{content?.title}</strong></Typography>
            </Stack>
            <Typography variant="h6" gutterBottom>{content?.description}</Typography>
          </HeaderContent>
        </Col>
      </Row>
    </Container>
  )
}
